<script setup>
import { onMounted, ref, watch } from 'vue';
import Preloader from './components/layout/Preloader';
import { useDark, useToggle } from '@vueuse/core';
import ConfirmDialog from 'primevue/confirmdialog';
import { useRouter } from 'vue-router';
import Toast from 'primevue/toast';

const router = useRouter();
const isLoading = ref(true);
const isDark = useDark({
	selector: 'html',
	attribute: 'class',
	valueDark: 'theme-dark',
	valueLight: '',
});
const toggleDark = useToggle(isDark);

onMounted(() => {
	setTimeout(() => {
		isLoading.value = false;
	}, 2000);
});
</script>

<template>
	<div>
		<Preloader v-if="isLoading" />
		<ConfirmDialog :style="{ width: '21rem' }"></ConfirmDialog>
		<Toast></Toast>

		<router-view v-slot="{ Component }">
			<transition
				enter-active-class="animated fadeIn"
				leave-active-class="animated fadeOut"
				mode="out-in"
				:duration="300"
				@after-leave="$root.$emit('triggerScroll')"
			>
				<component :is="Component" />
			</transition>
		</router-view>
		<div class="dark-version">
			<label id="switch" class="switch">
				<input type="checkbox" @change="toggleDark()" id="slider" />
				<span class="slider round"></span>
			</label>
		</div>

		<div class="whatsapp-icon">
			<a href="https://wa.link/gdx4be" target="_blank">
				<i class="fab fa-whatsapp"></i>
			</a>
		</div>
	</div>
</template>

<style>
.whatsapp-icon a {
	color: #fff !important;
	background-color: #25d366;
	width: 70px;
	height: 70px;
	display: flex;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	font-size: 50px;
	position: fixed;
	z-index: 99;
	bottom: 20px;
	right: 20px;
}
</style>
