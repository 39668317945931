import { createWebHistory, createRouter } from 'vue-router';
import Routes from '@/router/routes';
import { toRaw } from 'vue';

const router = createRouter({
	history: createWebHistory('/'),
	linkExactActiveClass: 'active',
	routes: Routes,
	scrollBehavior() {
		return { top: 0, behavior: 'smooth' };
	},
});

router.beforeResolve((to, from, next) => {
	if (from.query.ad_network) {
		if (Object.keys(to.query) == 0 && Object.keys(from.query) != 0) {
			to.query = from.query;
			return next({ path: to.path, query: to.query, replace: true });
		}
	}

	return next();
});

router.beforeEach((to, from, next) => {
	const isLoggedIn = localStorage.getItem('token');
	const userData = JSON.parse(localStorage.getItem('userData'));
	// if (Object.keys(from.query).length > 0) {
	// 	next({ path: to.path, query: { ...from.query }, replace: true });
	// } else {
	// 	next();
	// }

	// to.query = { ...from.query };

	// to.query.ttt ??= 'd';

	if (to.meta.requiresAuth && !isLoggedIn) {
		next({ name: 'login', query: { ...from.query }, replace: true });
	}

	if (
		isLoggedIn &&
		to.meta.requiresAuth &&
		to.name != 'phoneVerify' &&
		userData.account_request.phone_verify == '0'
	) {
		console.log('phone verify');
		next({ name: 'phoneVerify' });
	}

	if (isLoggedIn && to.meta.requiresAuth && to.name == 'phoneVerify' && userData.account_request.phone_verify == 1) {
		next({ name: 'account' });
	}

	if (to.meta.requiresGuest && isLoggedIn) {
		next({ name: 'account', query: { ...from.query }, replace: true });
	}

	return next();
});

export default router;
