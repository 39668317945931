import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import BootstrapVue3 from 'bootstrap-vue-3';
import Vue3Toastify from 'vue3-toastify';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import Paginator from 'primevue/paginator';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Aura from '@primevue/themes/aura';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Loading from '@/components/global/Loading.vue';
import vue3GoogleLogin from 'vue3-google-login'
import '@/plugins/echo.js';

import 'vue3-toastify/dist/index.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import './assets/custom.scss';
import './assets/css/dark-style.css';

const pinia = createPinia();
const app = createApp(App);
app.use(router);
app.use(ConfirmationService);
app.use(ToastService);

app.config.globalProperties.$resetFormValues = (formInputs) => {
	Object.keys(formInputs).forEach((key) => (formInputs[key] = null));
	return formInputs;
};

app.provide('$resetFormValues', app.config.globalProperties.$resetFormValues);
app.component('Paginator', Paginator);
app.component('Checkbox', Checkbox);
app.component('Loading', Loading);
app.component('Button', Button);

app.use(BootstrapVue3);
app.use(Vue3Toastify, {
	autoClose: 3000,
	rtl: true,
});
app.use(pinia);
app.use(PrimeVue, {
	theme: {
		preset: Aura,
		options: {
			primary: {
				50: '{noir.50}',
				100: '{noir.100}',
				200: '{noir.200}',
				300: '{noir.300}',
				400: '{noir.400}',
				500: '{noir.500}',
				600: '{noir.600}',
				700: '{noir.700}',
				800: '{noir.800}',
				900: '{noir.900}',
				950: '{noir.950}',
			},
		},
	},
});
app.use(vue3GoogleLogin, {
	clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
});
app.mount('#app');
